<template>
  <svg class="svg_margin" width="28" height="28" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.0312 10.7224C10.6583 10.7224 10.3006 10.5742 10.0369 10.3105C9.77316 10.0467 9.625 9.68906 9.625 9.3161V7.91547C8.87908 7.91547 8.16371 8.21179 7.63626 8.73924C7.10882 9.26668 6.8125 9.98205 6.8125 10.728V13.4156L24.375 13.0002V10.3125C24.375 9.56658 24.0787 8.85121 23.5512 8.32376C23.0238 7.79632 22.3084 7.5 21.5625 7.5V8.90625C21.5625 9.27921 21.4143 9.6369 21.1506 9.90062C20.8869 10.1643 20.5292 10.3125 20.1562 10.3125C19.7833 10.3125 19.4256 10.1643 19.1619 9.90062C18.8982 9.6369 18.75 9.27921 18.75 8.90625V7.5L12.4375 7.91547V9.3161C12.4375 9.68906 12.2893 10.0467 12.0256 10.3105C11.7619 10.5742 11.4042 10.7224 11.0312 10.7224ZM18.75 4.6875L12.4375 5.10297V3.69297C12.4375 3.32001 12.2893 2.96233 12.0256 2.69861C11.7619 2.43488 11.4042 2.28672 11.0312 2.28672C10.6583 2.28672 10.3006 2.43488 10.0369 2.69861C9.77316 2.96233 9.625 3.32001 9.625 3.69297V5.10297C8.13316 5.10297 6.70242 5.69561 5.64752 6.7505C4.59263 7.80539 4 9.23613 4 10.728V21.978C4 23.4698 4.59263 24.9006 5.64752 25.9555C6.70242 27.0103 8.13316 27.603 9.625 27.603L21.5625 27.1875C23.0543 27.1875 24.4851 26.5949 25.54 25.54C26.5949 24.4851 27.1875 23.0543 27.1875 21.5625V10.3125C27.1875 8.82066 26.5949 7.38992 25.54 6.33502C24.4851 5.28013 23.0543 4.6875 21.5625 4.6875V3.28125C21.5625 2.90829 21.4143 2.5506 21.1506 2.28688C20.8869 2.02316 20.5292 1.875 20.1562 1.875C19.7833 1.875 19.4256 2.02316 19.1619 2.28688C18.8982 2.5506 18.75 2.90829 18.75 3.28125V4.6875ZM6.8125 16.2281V21.978C6.8125 22.7239 7.10882 23.4393 7.63626 23.9667C8.16371 24.4942 8.87908 24.7905 9.625 24.7905L21.5625 24.375C22.3084 24.375 23.0238 24.0787 23.5512 23.5512C24.0787 23.0238 24.375 22.3084 24.375 21.5625V15.8127L6.8125 16.2281Z" fill="white"/>
</svg>
</template>

<script setup>
</script>

<style>

</style>