<template>
  <div class="home">
    <div class="head">
    <h1>Калькулятор</h1>
    <p class="head_p">
      Сколько бы вы платили, если бы оплачивали товар каждый день! Калькулятор рассчитает его стоимость в ежедневных платежах.
    </p>
    <h2>Инструкция по использованию калькулятора</h2>
    <ol>
      <li>Введите дату начала использования товара.</li>
      <li>Укажите стоимость товара.</li>
      <li>Можете определить срок использования товара (в днях, месяцах или годах) или укажите дату его окончания.</li>
      <li>При желании, внесите данные о доходах (они нужны для расчета процента, который вы будете тратить в месяц от заработной платы).</li>
    </ol>
  </div>
    <transition-group tag="div" name="fade" class="flex_de">
      <card
        v-if="!dynForm.main" 
        :name="randomSample.name"
        :cost="randomSample.cost"
        :date="randomSample.date"
        :desc="randomSample.desc"
        key="random-card"
      />
      <div class="form">
        <div class="header_input input">
          <p>Дата</p>
          <div class="input_block">
            <input
              type="date"
              ref="dateInput"
              v-model="date"
              :placeholder="randomSample.date"
              @input="mainInput"
            />
            <cal @mousedown="openDatePicker" />
          </div>
        </div>
        <div class="header_input input">
          <p>Стоимость</p>
          <div class="input_block">
            <input
              type="text"
              v-model.number="cost"
              :placeholder="randomSample.cost"
              @input="mainInput"
            />
            <rub />
          </div>
        </div>
      </div>
      <card
        v-if="dynForm.main"
        style="margin-top: 10px;"
        name="Ваш товар"
        :cost="cost"
        :date="date"
        :revenue="revenue"
        :finish_date="finish_date"
        desc="Здесь при сохранении вы сможете оставить комментарий или ссылку"
        key="main-card"
      />
      <div class="form space_between " v-if="dynForm.finish">
        <div class="header_input input">
          <p>Сколько вы планируете использовать</p>
          <div class="flex space_between gap">

            <div class="flex_column">
              <div class="input_block">
              <input class="mini" type="text" v-model.number="finish_days" placeholder="дни"/>
            </div>
            <p>дни</p>
            </div>
            
            <div class="flex_column">
              <div class="input_block">
              <input class="mini" type="text" v-model.number="finish_months" placeholder="мес"/>
            </div>
            <p>мес</p>
            </div>
            
            <div class="flex_column"><div class="input_block">
              <input class="mini" type="text" v-model.number="finish_years" placeholder="год"/>
            </div>
            <p>год</p></div>
            

          </div>
        </div>
        <div class="header_input input">
          <p>До какой даты</p>
          <div class="input_block">
            <input type="date" ref="datePicker2" v-model="finish_date" />
<cal @mousedown="openDatePicker2" />
          </div>
        </div>
      </div>
      <div class="form" v-if="dynForm.revenue">
        <div class="header_input input">
          <p>Сколько денег вы зарабатываете в месяц?</p>
          <div class="input_block">
            <input type="text" v-model.number="revenue" />
            <rub />
          </div>
        </div>
      </div>
      <a class="button33" @click="addToCart" v-if="dynForm.main">+ в мои покупки</a>
    </transition-group>


    <div v-if="isModalVisible" class="modal">
      <div class="modal-content">
        <span class="close" @click="isModalVisible = false">&times;</span>
        <div class="header_input input">
          <p>Нвзвание</p>
          <div class="input_block">
            <input
              type="text"
              v-model="name"
              placeholder="Твой товар"
            />
          </div>
        </div>
        <div class="header_input input">
          <p>Описание</p>
          <div class="input_block">
            <textarea class="input_block" id="desc" v-model="desc" placeholder="Здесь при сохранении вы сможете оставить комментарий или ссылку"></textarea>
          </div>
        </div>
        <a class="button33" @click="save">Добавить</a>
      </div>
    </div>
  </div>
  <a style="    color: #0000003b;
" href="https://t.me/mwscore">tg @mvscore</a>
</template>

<script setup>
let samples = [
  {
    name: "Автомобиль 'Летающая Карета'",
    cost: 800000,
    date: "2024-01-05",
    desc: "Купил, чтобы чувствовать себя как в фильме о будущем. Но на дорогах пробки всё ещё есть, и летать мне никто не разрешает.",
  },
  {
    name: "Робо-Шеф (который не готовит)",
    cost: 650000,
    date: "2023-10-12",
    desc: "Купил, чтобы забыть о готовке. Теперь забываю только о том, где оставил свою книгу рецептов, потому что готовлю я по-прежнему сам.",
  },
  {
    name: "Умный Зонт 'Не дождись'",
    cost: 50000,
    date: "2023-06-20",
    desc: "Купил, чтобы не попадать под дождь. Он правда умный, и складывается каждый раз, когда видит грозу.",
  },
  {
    name: "Массажная Кровать 'Отдохновение Титанов'",
    cost: 900000,
    date: "2024-02-10",
    desc: "Купил, чтобы как следует выспаться и расслабиться. Теперь понимаю, что титанам явно нравятся жесткие кровати с вибрацией.",
  },
  {
    name: "Дрон-почтальон (который потерял почту)",
    cost: 150000,
    date: "2023-11-03",
    desc: "Купил, чтобы доставлять себе посылки быстрее. Теперь мои посылки летают куда-то, но не ко мне.",
  },
  {
    name: "Робот-пылесос 'Мастер Перепадов'",
    cost: 120000,
    date: "2023-07-15",
    desc: "Купил, чтобы не убираться. Но он предпочитает кататься и застревать в местах, где уборка не требуется.",
  },
  {
    name: "Кухонный Комбайн 'Шеф Лентяй'",
    cost: 85000,
    date: "2023-05-25",
    desc: "Купил, чтобы приготовить сложные блюда. Но теперь сложность — уговорить его не отказываться работать.",
  },
  {
    name: "Палатка 'Гламурный Кемпинг'",
    cost: 130000,
    date: "2023-08-19",
    desc: "Купил, чтобы наслаждаться природой с комфортом. Но палатка отказалась быть комфортной, особенно в дождь.",
  },
  {
    name: "Робот 'Няня для взрослых'",
    cost: 500000,
    date: "2024-01-30",
    desc: "Купил, чтобы заботиться о себе. Но теперь он только напоминает мне, что я должен убираться и ложиться вовремя.",
  },
  {
    name: "Золотой Тостер 'Хруст Императора'",
    cost: 75000,
    date: "2023-12-15",
    desc: "Купил, чтобы сделать самые лучшие тосты. Теперь я понимаю, что цена золотого не значит, что хлеб не будет подгорать.",
  },
];
import card from "@/components/card.vue";
import { ref, computed, onMounted } from "vue";
import rub from "@/assets/rub.vue";
import cal from "@/assets/cal.vue";
import { reactive } from "vue";
import { watch } from "vue";

const name = ref("")
const desc = ref("")
const cost = ref(0);
const date = ref("-");
const revenue = ref(0);
const finish_date = ref(null);
const finish_days = ref(0);
const finish_months = ref(0);
const finish_years = ref(0);

const randomSample = reactive({});
const isModalVisible = ref(false);

onMounted(() => {
  updateRandomSample();
  setInterval(updateRandomSample, 4500);
});

const updateRandomSample = () => {
  Object.assign(randomSample, samples[Math.floor(Math.random() * samples.length)]);
};

const openDatePicker = () => {
  const dateInput = document.querySelector('input[type="date"]');
  if (dateInput) {
    dateInput.showPicker();
  }
};
const datePicker2 = ref(null);

const openDatePicker2 = () => {
  if (datePicker2.value) {
    datePicker2.value.showPicker();
  }
};

const dynForm = computed(() => {
  return {
    main: cost.value !== 0 || date.value !== "-",
    finish: cost.value !== 0 && date.value !== "-",
    revenue: finish_date.value !== null,
  };
});

// Флаги для предотвращения бесконечных циклов обновления
let isUpdatingFromDate = false;
let isUpdatingFromFields = false;

// Функция для вычисления разницы между датами в годах, месяцах и днях
function calculateDifference(startDate, endDate) {
  let years = endDate.getFullYear() - startDate.getFullYear();
  let months = endDate.getMonth() - startDate.getMonth();
  let days = endDate.getDate() - startDate.getDate();

  if (days < 0) {
    months -= 1;
    days += new Date(endDate.getFullYear(), endDate.getMonth(), 0).getDate();
  }

  if (months < 0) {
    years -= 1;
    months += 12;
  }

  return { years, months, days };
}

// Функция для добавления годов, месяцев и дней к дате
function addDurationToDate(startDate, years, months, days) {
  const newDate = new Date(startDate);
  newDate.setFullYear(newDate.getFullYear() + years);
  newDate.setMonth(newDate.getMonth() + months);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
}

function addToCart() {
  isModalVisible.value = true
}
function save() {
  let data = JSON.parse(localStorage.getItem('data')) || [];

  // Создаем объект с текущими значениями
  let item = {
    name: name.value, // или используйте соответствующую переменную, если она есть
    cost: cost.value,
    date: date.value,
    revenue: revenue.value,
    finish_date: finish_date.value,
    desc: desc.value // или используйте соответствующую переменную
  };

  // Добавляем новый объект в массив данных
  data.push(item);

  // Сохраняем обновленный массив обратно в localStorage
  localStorage.setItem('data', JSON.stringify(data));
  isModalVisible.value = false
}

// Наблюдатель за finish_date
watch(finish_date, (newVal) => {
  if (isUpdatingFromFields) return;
  isUpdatingFromDate = true;
  const startDate = new Date(date.value);
  const endDate = new Date(finish_date.value);
  if (isNaN(startDate) || isNaN(endDate)) {
    finish_days.value = 0;
    finish_months.value = 0;
    finish_years.value = 0;
  } else {
    const diff = calculateDifference(startDate, endDate);
    finish_days.value = diff.days;
    finish_months.value = diff.months;
    finish_years.value = diff.years;
  }
  isUpdatingFromDate = false;
});

// Наблюдатель за finish_days, finish_months и finish_years
watch([finish_days, finish_months, finish_years], ([days, months, years]) => {
  if (isUpdatingFromDate) return;
  isUpdatingFromFields = true;
  const startDate = new Date(date.value);
  if (isNaN(startDate)) {
    finish_date.value = null;
  } else {
    const newDate = addDurationToDate(startDate, years, months, days);
    finish_date.value = newDate.toISOString().substring(0, 10);
  }
  isUpdatingFromFields = false;
});

// Дополнительный наблюдатель за date, чтобы обновлять finish_date при изменении начальной даты
watch(date, (newVal) => {
  if (date.value && finish_date.value) {
    // Пересчитываем finish_date при изменении date
    const newStartDate = new Date(date.value);
    const duration = {
      years: finish_years.value,
      months: finish_months.value,
      days: finish_days.value,
    };
    const newFinishDate = addDurationToDate(newStartDate, duration.years, duration.months, duration.days);
    finish_date.value = newFinishDate.toISOString().substring(0, 10);
  }
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
li,h2,h3,h1{
  font-family: "Inter", sans-serif;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
.button33 {
  animation: pulse 2s infinite;
}
.button33:hover {
  background-color: #333;
  color: #fff;
  transform: scale(1.01);
}
.input_block:hover {
  background-color: #333;
  color: #fff;
  transform: scale(1.01);
}
.button33 {
  transition: background-color 0.3s, transform 0.3s;
}
.input_block:hover {
  transition: background-color 0.3s, transform 0.3s;
}
.button33{
  background-color: #000;
  border-radius: 15px;
  padding: 5px 15px;
  color: #ffffffe3;
  outline: none; /* Убирает фокусную обводку ( например, синюю линию в браузере) */
  border: none; /* Убирает границу вокруг элемента */
  box-shadow: none; /* Убирает любые тени */
  height: 30px;
  font-size: 24px;
  cursor: pointer;
}
input {
  border-radius: 15px;
  padding: 5px 15px;
  color: #ffffffe3;
  outline: none; /* Убирает фокусную обводку ( например, синюю линию в браузере) */
  border: none; /* Убирает границу вокруг элемента */
  box-shadow: none; /* Убирает любые тени */
  background: none;
  height: 30px;
  font-size: 24px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  visibility: hidden;
}
.space_between{
  justify-content: space-between
}
.flex_de{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
input {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  max-width: 400px;
  text-align: left;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
.mini{
  max-width: 100px;
}
.gap{
  display: flex;
  gap: 3px;
}
.flex {
  display: flex;
}

.svg_margin {
  margin-right: 10px;
}

.home {
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding: 20px;
}

.input_block {
  background-color: #000;
  border-radius: 15px;
  color: #ffffffe3;
  display: flex;
  align-items: center;
  width: 100%;
}

.form {
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
}

.head {
  margin-top: 20px;
}

.head_p {
  font-size: 28px;
  max-width: 100%;
}
.flex_column{
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .form {
    flex-direction: column;
  }
  .input_block {
    justify-content: space-between;
  }
  .head_p {
    font-size: 20px;
  }
  input {
    font-size: 18px;
  }
}

@media screen and (max-width: 480px) {
  .input_block {
    justify-content: space-between;
  }
  .head_p {
    font-size: 16px;
  }
  input {
    font-size: 16px;
  }
}



.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 15px;
  max-width: 500px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.close {
  position: absolute;
  right: 15px;
  top: 10px;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.input-group {
  margin-bottom: 15px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
}

textarea{
  font-size: 20px;  
}
</style>