<template>
  <div class="card">
    
    <div class="card-content">
        <transition name="fade">
      <div class="header">
        <p class="bold">{{ name }}</p>
          <p class="description" v-if="percentOfRevenue !== null && percentOfRevenue > 100"> {{ percentOfRevenue }}% больше твоей зп
          </p>
          <p class="description" v-else-if="percentOfRevenue !== null && percentOfRevenue <= 100">{{ percentOfRevenue }}% процентов от зп
          </p>
        <p class="description">{{ desc }}</p>
      </div>
    </transition>
      <div class="stat">
        <div class="date_stat">
          <p class="bold p_desc description">{{ date }}<span v-if="finish_date"> - {{ finish_date }}</span></p>
          <p class="mini" :style="{ color: '#86FF6B' }">
            {{ daysBetween }} дней
          </p>
          <p class="mini" :style="{ color: '#FFF47C' }">
            {{ monthsBetween }} мес
          </p>
          <p class="mini" :style="{ color: '#FF7171' }">
            {{ yearsBetween }} год/лет
          </p>
        </div>

        <div class="cost_stat">
          <p class="bold">{{ cost }}</p>
          <p class="mini" :style="{ color: '#86FF6B' }">
            {{ costPerDay }}/день
          </p>
          <p class="mini" :style="{ color: '#FFF47C' }">
            {{ costPerMonth }}/мес
          </p>
          <p class="mini" :style="{ color: '#FF7171' }">
            {{ costPerYear }}/год
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script setup>
import { ref, computed } from "vue";

const { name, desc, cost, date, finish_date, revenue } = defineProps({
  name: String,
  desc: String,
  cost: Number,
  date: String,
  revenue: Number,
  finish_date: String,
});

const today = ref(new Date());

const endDate = computed(() => {
  return finish_date ? new Date(finish_date) : today.value;
});

const daysBetween = computed(() => {
  if (!date) return 0;
  const purchaseDate = new Date(date);
  const diffTime = Math.abs(endDate.value - purchaseDate);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
});

const monthsBetween = computed(() => {
  return (daysBetween.value / 30).toFixed(2);
});

const yearsBetween = computed(() => {
  return (daysBetween.value / 365).toFixed(2);
});

const costPerDay = computed(() => {
  return daysBetween.value > 0 ? (cost / daysBetween.value).toFixed(2) : 0;
});

const costPerMonth = computed(() => {
  return monthsBetween.value > 1
    ? (cost / monthsBetween.value).toFixed(2)
    : cost;
});

const costPerYear = computed(() => {
  return yearsBetween.value > 1
    ? (cost / yearsBetween.value).toFixed(2)
    : cost;
});

const percentOfRevenue = computed(() => {
  if (!revenue) return null;

  const result = (costPerMonth.value / revenue) * 100;
  const roundedResult = Math.round(result);

  return roundedResult;
});
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.card {
  background-color: #000;
  border-radius: 15px;
  color: #ffffffe3;
  display: flex;
  justify-content: space-between;
}
.card-content {
  margin: 15px 25px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 768px){
    .card-content{
        flex-direction: column;
    }
    .stat{
        margin-top: 20px;
    }
    
}
.date_stat,
.cost_stat {
  gap: 2px;
  display: flex;
  flex-direction: column;
}
.description {
  color: #ffffff59;
}
.mini {
  font-size: 12px;
  font-weight: 500;
}
.bold {
  font-size: 20px;
  font-weight: 500;
}

.stat {
  display: flex;
  gap: 10px;
  width: -webkit-fill-available;
  justify-content: flex-end;
}
.header{
    width: -webkit-fill-available;
}
</style>