<template>
  <svg class="svg_margin" width="19" height="21" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.5 14.5C15.6217 14.5 17.6566 13.6571 19.1569 12.1569C20.6571 10.6566 20.5 10.1217 20.5 8C20.5 5.87827 19.6571 3.84344 18.1569 2.34315C16.6566 0.842855 14.6217 0 12.5 0H5C4.60218 0 4.22064 0.158035 3.93934 0.43934C3.65804 0.720644 3.5 1.10218 3.5 1.5L4.5 11.5H2C1.60218 11.5 1.22064 11.658 0.93934 11.9393C0.658035 12.2206 0.5 12.6022 0.5 13C0.5 13.3978 0.658035 13.7794 0.93934 14.0607C1.22064 14.342 1.60218 14.5 2 14.5H4.5V16.5H2C1.60218 16.5 1.22064 16.658 0.93934 16.9393C0.658035 17.2206 0.5 17.6022 0.5 18C0.5 18.3978 0.658035 18.7794 0.93934 19.0607C1.22064 19.342 1.60218 19.5 2 19.5H4.5V21.5C4.5 21.8978 4.65804 22.2794 4.93934 22.5607C5.22064 22.842 5.60218 23 6 23C6.39782 23 6.77936 22.842 7.06066 22.5607C7.34196 22.2794 7.5 21.8978 7.5 21.5V19.5H13C13.3978 19.5 13.7794 19.342 14.0607 19.0607C14.342 18.7794 14.5 18.3978 14.5 18C14.5 17.6022 14.342 17.2206 14.0607 16.9393C13.7794 16.658 13.3978 16.5 13 16.5H7.5V14.5H13.5ZM6.5 3H12.5C13.8261 3 15.0979 3.52678 16.0355 4.46447C16.9732 5.40215 17.5 6.67392 17.5 8C17.5 9.32608 17.9732 9.09785 17.0355 10.0355C16.0979 10.9732 14.8261 11.5 13.5 11.5H7.5L6.5 3Z" fill="white"/>
</svg>
</template>

<script setup>

</script>

<style>

</style>