<template>
  <div class="wrap">
    <h1>KUPILZA.RU</h1>
    <nav>
      <router-link to="/">Калькулятор</router-link> |
      <router-link to="/products">Мои покупки</router-link>
    </nav>
  </div>
  <div class="wrap">
    <router-view class="appd" />
  </div>
</template>


<style lang="scss">
#app {
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
  margin: 0;
  padding: 0;
}
h1,
p {
  margin: 0;
}
.wrap {
  display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    align-items: center;
}
.appd {
  max-width: 1000px;
}
nav {
  background-color: #000;
  border-radius: 30px;
  width: fit-content;
  padding: 5px 15px;

  a {
    text-decoration: none;
    font-weight: bold;
    color: #ffffff40;

    &.router-link-exact-active {
      color: #ffffff;
    }
  }
}
</style>
