import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/Culculator.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta:{
      title:"Калькулятор расходов, сколько ты платишь в день?",
      description:'Рассчитайте, сколько стоил бы товар при оплате каждый день. Наш калькулятор поможет вам понять, как управлять своими расходами и экономить на покупках.'
    }
  },
  {
    path: '/products',
    name: 'products',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/MyProducts.vue')
    },
    meta:{
      title:"Сохраненные товары. KUPILZA.RU",
      description:""
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to) => {
  const { title, description } = to.meta;
  const defaultTitle = 'Калькулятор расходов, сколько ты платишь в день?';
  const defaultDescription = 'Рассчитайте, сколько стоил бы товар при оплате каждый день. Наш калькулятор поможет вам понять, как управлять своими расходами и экономить на покупках.';

  document.title = title || defaultTitle

  const descriptionElement = document.querySelector('head meta[name="description"]')

  descriptionElement.setAttribute('content', description || defaultDescription)
})

export default router
